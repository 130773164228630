<template>
  <setup-table
    :title="`Budget Code`"
    :items="items"
    :headers="headers"
    :loading="loading"
    :clearItem="!showForm || !editedItem.id"
    @createItem="createItem()"
    @editItem="editItem"
    @deleteItems="deleteItems"
  >
    <template #itemForm v-if="showForm">
      <budget-code-form
        ref="budgetCodeForm"
        :sections="budgetCodeStructure"
        :value="editedItem"
        @submit="saveItem"
        @closeForm="closeForm"
        @formChanges="formChanges"
      ></budget-code-form>
    </template>
  </setup-table>
</template>

<script>
import { mapActions } from 'vuex';
import { toLowerCamelCase } from '@/util';
import { GET_CONFIG } from '@/store/modules/Config/actions';
import { GET_BUDGET_CODES, SAVE_BUDGET_CODE, DELETE_BUDGET_CODES } from '@/store/modules/BudgetCode/actions';
import SetupTable from './SetupTable.vue';
import BudgetCodeForm from './BudgetCodeForm.vue';
import promptBeforeLeave from '@/mixins/promptBeforeLeave';
import { cloneDeep } from 'lodash';

export default {
  name: 'BudgetCodeSetupTable',
  inject: ['eventHub'],
  components: { SetupTable, BudgetCodeForm },
  data() {
    return {
      showForm: false,
      items: [],
      editedItem: {},
      loading: true,
      budgetCodeStructure: [],
    };
  },
  computed: {
    headers() {
      if (this.budgetCodeStructure.length)
        return [{ text: 'Name', value: 'name' }].concat(
          this.budgetCodeStructure.map((e) => ({ text: e.name, value: 'code.' + toLowerCamelCase(e.name) }))
        );
      else return [{ text: 'Name', value: 'name' }];
    },
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('config', [GET_CONFIG]),
    ...mapActions('budgetCode', [GET_BUDGET_CODES, SAVE_BUDGET_CODE, DELETE_BUDGET_CODES]),
    async fetchItems() {
      const result = await this.getConfig('budgetCode');
      this.budgetCodeStructure = Array.isArray(result) ? result : [];
      const budgetCodes = await this.getBudgetCodes();
      for (const bc of budgetCodes) {
        if (this.budgetCodeStructure?.length > 0) bc.code = bc.code || {};
        else bc.code = '';
      }
      this.items = budgetCodes;
      this.loading = false;
    },
    async createItem() {
      const yes = await this.performPromptBeforeLeave();

      if (!yes) return;
      this.editedItem = { name: '', code: {} };
      this.baseFormValue = cloneDeep(this.editedItem);
      this.latestFormValue = cloneDeep(this.editedItem);
      this.showForm = true;
    },
    async editItem(item) {
      const yes = await this.performPromptBeforeLeave();

      if (!yes) return;
      this.editedItem = { ...item };
      this.baseFormValue = cloneDeep(this.editedItem);
      this.latestFormValue = cloneDeep(this.editedItem);
      this.showForm = true;
    },
    async saveItem(item) {
      try {
        const r = await this.saveBudgetCode({ ...item, code: item.code || {} });
        if (r && r.id) {
          this.$myalert.success('Budget Code saved');
          await this.fetchItems();
          this.editedItem = { ...item, id: r.id };
          this.baseFormValue = cloneDeep(this.editedItem);
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async deleteItems(items) {
      const yes = await this.$myconfirm(
        `Are you sure you want to delete ${
          items.length > 1 ? 'these Budget Codes' : 'this Budget Code'
        }? This operation cannot be undone.`
      );
      if (yes) {
        const r = await this.deleteBudgetCodes(items.map((e) => e.id));
        if (r.done) {
          this.$myalert.success(`${items.length > 1 ? 'Budget Codes' : 'Budget Code'} deleted`);
          this.showForm = false;
          this.fetchItems();
        }
      }
    },
    closeForm() {
      this.showForm = false;
      this.baseFormValue = null;
      this.latestFormValue = null;
    },
    formChanges(value) {
      this.latestFormValue = cloneDeep(value);
    },
  },
  mixins: [promptBeforeLeave],
};
</script>

<style lang="scss">
.table-action {
  margin-left: 10px;
}
</style>
